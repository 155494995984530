.gd-filter-bar{
    display: flex;
    justify-content: space-around;
}

.edit-mode-block{
    display: flex;
    justify-content: space-around;
}

.display-mode .edit-mode-block{
    display: none;
}

.edit-mode div.status-label{
    display: none;
}

.display-mode{
    cursor: pointer;
}

.edit-mode i{
    cursor: pointer;
}

.edit-mode{
    /* pointer-events: ; */
}
