.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.confirm-modal{
    background: white;
    width: clamp(50%,700px,90%);
    height: min(50%,300px);
    border: 5px;
    margin: auto;
    padding: 0 2rem;
}