.skelton{
    background: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}

.skelton.text{
    width: 100%;
    height: 12px;
}

.skelton.title{
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skelton.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skelton.thumbnail{
    width: 100px;
    height: 100px;
}

.skelton.card-image{
    width: 185px;
    height: 185px;
}

.skelton-wrapper{
    margin: 20px auto;
    position: relative;
    overflow: hidden;
}

.skelton-logo-type-card{
    /* width: 200px; */
    padding:  0 10px 20px;
    background: #f7f7f7;
}


.shimmer-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: shimmer-loading 1.5s infinite;
    
}

.shimmer{
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes shimmer-loading{
    0%{transform: translate(-150%);}
    50%{transform: translate(-60%);}
    100%{transform: translate(150%);}
}
