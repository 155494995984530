.gds-dashboard-header{
    padding: 30px 0;
    position: relative;
}

/* .gds-dashboard-header::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: red;
} */

.gds-header-overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #55014269;
    z-index: -1;
}

.gds-bg{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.gds-dashboard-header .gds-logo-contaner img.gds-logo{
    width: 200px;
    
}

.gds-logo-contaner{
    display: flex;
    justify-content: center;
    align-items: center;
}

.statistics-container{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.statistics-container .state span{
    color: #fff;
}

.statistics-container .state span.state-number{
   font-size: 55px;
   font-weight: 600;
}

.statistics-container .state span.state-text{
   font-size: 20px;
   font-weight: 300;
}

.statistics-container .state{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

nav.gds-menu{
    padding: 70px 8vw;
}

nav.gds-menu ul{
    border-left: 8px solid #550142;
    padding-left: 22px;
}

li.gds-dashboard-link {
    list-style-type: none;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 3rem;
    color: #550142;
}

li.gds-dashboard-link a {
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 3rem;
    color: #550142;
    transition: all 0.5s ease;
}
li.gds-dashboard-link a:hover {
    color: #F72585;
    margin-left: 10px;
}