.filter-bar{
    background-color: #1d1b31;
    grid-area: filter;
    padding: 25px;
    color: white;
    border-radius: 5px;
}

.filter-bar .name-filter input{
    margin-left: 6px;
}

.filter-bar .type-filter ul{
    display: flex;
    padding: 0;
    justify-content: space-between;
    margin-top: 14px;
}

.filter-bar .type-filter ul li{
    list-style-type: none;
    font-family: 'POPPINS';
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.filter-bar .type-filter ul li.active,.filter-bar .type-filter ul li:hover{
    color: deeppink;
}

.liked-logo-listing{
    grid-area: listing;
}

.right-sidebar{
    background-color: #1d1b31;
    grid-area: sidebar;
    padding: 25px;
    color: white;
}

.liked-logo-grid-wrapper {
    display: grid;
    grid-template-rows: 120px auto;
    grid-template-columns: auto 242px;
    grid-template-areas:
        'filter sidebar'
        'listing sidebar';
    grid-gap: 21px;
    padding: 20px;
}

.liked-logo-manager-container h1{
    margin-left: 20px;
    margin-top: 20px;
}

.right-sidebar .pagination-container .page{
    width: 30px;
    height: 30px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
}
.right-sidebar .pagination-container .page.active,.right-sidebar .pagination-container .page:hover{
    background-color: deeppink;
    color: #1d1b31; 
}
.right-sidebar .pagination-container{
    display: flex;
    flex-wrap: wrap;
}

.server-info{
    background-color: #11101d;
    margin: 25px 0;
    border-radius: 5px;
}

.server-info .server-info-title{
    background: #000000;
    padding: 10px;
    font-family: 'POPPINS';
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 90% 10%;
}

.server-info-content{
    display: grid;
    grid-template-columns: 80% 20%;
    padding: 10px;
    grid-row-gap: 5px;
    font-family: 'Poppins';
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 22px;
    transition: all 0.5s ease;
    max-height: 400px;
}

.hidden-info{
    max-height: 0;
    padding: 0 ;
}

.server-info-content .value{
    justify-self: end;
    color: deeppink;
}

.server-info-title i{
    transition: all 0.5s ease;
    /* transform: rotate(180deg); */
    justify-self: center;
    align-self: center;
}

.server-info-title i.rotate{
    transform: rotate(180deg);
}

.edit-logo-form-container{
    background: #000000;
    padding: 15px;
    border-radius: 5px;
}

.edit-logo-form-container input, .edit-logo-form-container select{
    border-radius: 5px;
}

.edit-logo-form-container select{
    width: 100%;
    height: 27px;
}

.edit-logo-btn {
    background: deeppink;
    color: wheat;
    border-radius: 5px;
    justify-self: end;
}
.edit-logo-btn-container{
    display: grid;
}

.liked-logo{
    width: 160px;
    height: 160px;
    position: relative;
}

.liked-logo .overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000e1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all 0.5s ease;
    opacity: 0;
}

.liked-logo:hover .overlay{
    opacity: 1;
}

.liked-logo i.arrow{
    color: deeppink;
}
.liked-logo i.trash{
    color: white;
}
.liked-logo i{
    font-size: 22px;
    cursor: pointer;
}

.liked-logo-listing{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-template-rows: max-content;
    grid-row-gap: 20px;
}

.filter-bar input{
    border-radius: 5px;
}

.no-content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #7d7a9a78;
    color: #000;
}

.liked-logo.active{
    outline: 3px solid deeppink;
}